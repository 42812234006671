import * as React from 'react';

import { builder, BuilderComponent } from '@builder.io/react'
import { graphql } from 'gatsby'
import Navbar from '../components/navbarru'
import Footer from '../components/footerru'
import SEO from "../components/SEO/SEO";

builder.init('339a85b5ba2949c0a1db68f8fbf3cd46')

const MyComponent = (props) => {
  const content = props.data?.allBuilderModels.oneRu?.content;

  return <>
      <SEO
      title="Launch Turkey"
      description="Establish your business in Turkey" />
      <Navbar />
      <BuilderComponent
        content={content}
        model="ru" />
      <Footer />
      </>
}

export default MyComponent;      

export const query = graphql`
  query($path: String!) {
    allBuilderModels {
      oneRu(
        target: { urlPath: $path }
        options: { cachebust: true }
      ) { content }
    }
  }
`

// Register your components for use in the visual editor!
// https://www.builder.io/blog/drag-drop-react
const Heading = props => (
  <h1 className="my-heading">{props.title}</h1>
)