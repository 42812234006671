import React from 'react'

import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  ArrowPathIcon,
  Bars3Icon,
  BookmarkSquareIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  LifebuoyIcon,
  PhoneIcon,
  PlayIcon,
  ShieldCheckIcon,
  Squares2X2Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

const solutions = [
  {
    name: 'Launch Turkey Start',
    description: 'Запуск, развитие и управление Вашей компанией',
    href: '/ru/start',
    icon: ChartBarIcon,
  },
  {
    name: 'Launch Turkey PEO',
    description: 'Получите доступ к найму в Турции в 1 клик без Турецкого юридического лица',
    href: '/ru/peo',
    icon: CursorArrowRaysIcon,
  },
  {
    name: 'Launch Turkey Mailroom',
    description: "Получите бизнес-адрес и почтовый ящик",
    href: '/mailroom',
    icon: Squares2X2Icon,
  },
]
const callsToAction = [
  { name: 'Смотреть демо', href: '#', icon: PlayIcon },
  { name: 'Контактная информация', href: 'https://calendly.com/attorney-omer/launch-turkey?month=2023-02', icon: PhoneIcon },
]

const company = [
    { name: 'О нас', href: '/about', icon: PhoneIcon },
    { name: 'Вакансии', href: 'https://www.linkedin.com/company/launch-turkey/jobs/', icon: BookmarkSquareIcon },
  ]
const resources = [
  {
    name: 'Блог',
    description: 'Свежие новости, истории и успехи клиентов',
    href: '/guide',
    icon: LifebuoyIcon,
  },
  {
    name: "Руководство для основателей",
    description: 'Всё, что вы хотели бы узнать, и больше',
    href: '/guide',
    icon: BookmarkSquareIcon,
  },
  {
    name: 'Центр Поддержки',
    description: 'Полезные вопросы и ответы',
    href: '/guide',
    icon: CalendarIcon,
  },
  { name: 'A.S. против LTD. STI.', description: 'Узнайте, что именно подходит для Вашего бизнеса', href: '#', icon: ShieldCheckIcon },
]
const recentPosts = [
  { id: 1, name: 'Что нужно учесть перед открытием компании в Турции.', href: 'https://www.launchturkey.com/blog/what-to-consider-before-launching-a-turkish-company' },
  { id: 2, name: 'Регистрация компании в Турции', href: 'https://www.launchturkey.com/blog/incorporating-a-company-in-turkey' },
  { id: 3, name: 'Важные детали для владельцев Турецкой компании', href: 'https://www.launchturkey.com/blog/important-details-for-owners-of-a-turkish-company' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  return (
    <Popover className="relative bg-transparent">
      <div className="mx-auto max-w-7xl px-4 sm:px-6">
        <div className="flex items-center justify-betweenmd:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <a href="/">
              <img
                className="w-auto mt-5 sm:h-10"
                src="https://res.cloudinary.com/avomeraydin/image/upload/v1665649454/launch_turkey_200_e2sumj.svg"
                alt=""
              />
            </a>
          </div>
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center rounded-md p-2 text-white hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden space-x-10 md:flex">
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-white' : 'text-white',
                      'group inline-flex items-center rounded-md text-base font-medium hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                    )}
                  >
                    <span className='text-dec'>Услуги</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? 'text-gray-600' : 'text-gray-400',
                        'ml-2 h-5 w-5 group-hover:text-gray-500'
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 -ml-4 mt-3 w-screen max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative grid gap-6 menu-area px-5 py-6 sm:gap-8 sm:p-8">
                          {solutions.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-900 text-dec"
                            >
                              <item.icon className="h-6 w-6 flex-shrink-0 text-indigo-600" aria-hidden="true" />
                              <div className="ml-4">
                                <p className="text-base font-medium text-white text-dec">{item.name}</p>
                                <p className="mt-1 text-sm text-gray-400 text-dec">{item.description}</p>
                              </div>
                            </a>
                          ))}
                        </div>
                        <div className="space-y-6 menu-area px-5 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                          {callsToAction.map((item) => (
                            <div key={item.name} className="flow-root text-dec">
                              <a
                                href={item.href}
                                className="-m-3 flex items-center rounded-md p-3 text-base font-medium text-white hover:bg-gray-900 text-dec"
                              >
                                <item.icon className="h-6 w-6 flex-shrink-0 text-gray-400 text-dec" aria-hidden="true" />
                                <span className="ml-3">{item.name}</span>
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>

            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-white' : 'text-white',
                      'group inline-flex items-center rounded-md text-base font-medium hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                    )}
                  >
                    <span lassName='text-dec'>О компании</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? 'text-gray-600' : 'text-gray-400',
                        'ml-2 h-5 w-5 group-hover:text-gray-500'
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 -ml-4 mt-3 w-screen max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative grid gap-6 menu-area px-5 py-6 sm:gap-8 sm:p-8">
                          {company.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-900 text-dec"
                            >
                              <item.icon className="h-6 w-6 flex-shrink-0 text-indigo-600 text-dec" aria-hidden="true" />
                              <div className="ml-4">
                                <p className="text-base font-medium text-white text-dec">{item.name}</p>
                                <p className="mt-1 text-sm text-gray-400 text-dec">{item.description}</p>
                              </div>
                            </a>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>

            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-white' : 'text-white',
                      'group inline-flex items-center rounded-md text-base font-medium hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                    )}
                  >
                    <span lassName='text-dec'>Ресурсы</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? 'text-gray-600' : 'text-gray-400',
                        'ml-2 h-5 w-5 group-hover:text-gray-500'
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-md -translate-x-1/2 transform px-2 sm:px-0">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative grid gap-6 menu-area px-5 py-6 sm:gap-8 sm:p-8">
                          {resources.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-900 text-dec"
                            >
                              <item.icon className="h-6 w-6 flex-shrink-0 text-indigo-600 text-dec" aria-hidden="true" />
                              <div className="ml-4">
                                <p className="text-base font-medium text-white text-dec">{item.name}</p>
                                <p className="mt-1 text-sm text-gray-400 text-dec">{item.description}</p>
                              </div>
                            </a>
                          ))}
                        </div>
                        <div className="menu-area px-5 py-5 sm:px-8 sm:py-8">
                          <div>
                            <h3 className="text-base font-medium text-white">Недавние Посты</h3>
                            <ul role="list" className="mt-4 space-y-4">
                              {recentPosts.map((post) => (
                                <li key={post.id} className="truncate text-base text-dec">
                                  <a href={post.href} className="font-medium text-gray-400 hover:text-gray-700 text-dec">
                                    {post.name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className="mt-5 text-sm">
                            <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500 text-dec">
                              Просмотреть все сообщения
                              <span aria-hidden="true"> &rarr;</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
            <a href="/pricing" className="text-dec text-base font-medium text-white hover:text-gray-300">
            Цены
            </a>
          </Popover.Group>
          <a href="/" className="text-dec text-base font-medium text-white hover:text-gray-300">
              EN
            </a>
          <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
            <a href="https://dashboard.launchturkey.com/users/sign_in" className="whitespace-nowrap text-base font-medium text-white hover:text-gray-200 text-dec">
              Sign in
            </a>
            <a
              href="https://dashboard.launchturkey.com/users/sign_up"
              className="ml-8 text-dec inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-[#f47021] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-[#F37C3C]"
            >
              Sign up
            </a>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="menu-index absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden">
          <div className="divide-y-2 divide-gray-50 rounded-lg menu-area shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="px-5 pt-5 pb-6">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="h-8 w-auto"
                    src="https://res.cloudinary.com/avomeraydin/image/upload/v1665648075/logo_launch_lg8liu.svg"
                    alt="Your Company"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md menu-area p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {solutions.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-m-3 flex items-center rounded-md p-3 hover:bg-gray-900 text-dec"
                    >
                      <item.icon className="h-6 w-6 flex-shrink-0 text-indigo-600 text-dec" aria-hidden="true" />
                      <span className="ml-3 text-base font-medium text-white text-dec">{item.name}</span>
                    </a>
                  ))}
                </nav>
              </div>
            </div>
            <div className="space-y-6 py-6 px-5">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                <a href="#" className="text-base font-medium text-gray-400 hover:text-gray-700 text-dec">
                  Цены
                </a>

                <a href="#" className="text-base font-medium text-gray-400 hover:text-gray-700 text-dec">
                  документы
                </a>
                {resources.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="text-base font-medium text-gray-400 hover:text-gray-700 text-dec"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div>
                <a
                  href="#"
                  className="flex w-full items-center justify-center rounded-md border border-transparent text-dec bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                >
                  Sign up
                </a>
                <p className="mt-6 text-center text-base font-medium text-gray-200 text-dec">
                  Existing customer?{' '}
                  <a href="#" className="text-indigo-600 hover:text-indigo-500 text-dec">
                    Sign in
                  </a>
                </p>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
