import React from 'react'

export default function footer() {
  return (
    <>
    <footer id="footer" className="footer-2 wf-section">
  <div className="footer-container w-container">
    <div className="footer-flex-container-2">
      <a href="/" className="footer-logo-link-2">
        <img src="https://res.cloudinary.com/avomeraydin/image/upload/v1665649326/launch_turkey_vga1lw.svg" width="92" alt="Firstbase" className="image-108" />
      </a>
      <div className="div-block-368">
        <div className="div-block-369">
          <h2 className="footer-heading-2">Продукты</h2>
          <ul role="list" className="list-5 w-list-unstyled">
            <li className="list-item-3"><a href="/ru/start" className="footer-link"> Начинать</a></li>
            <li className="list-item-3"><a href="/mailroom" aria-current="page" className="footer-link w--current"> Почта</a></li>
            <li className="list-item-3"><a href="/ru/peo" className="footer-link"> PEO</a></li>
            <li className="list-item-3"><a href="/pricing" className="footer-link">Цены</a></li>
          </ul>
        </div>
        <div className="div-block-369">
          <h2 className="footer-heading-2">Компания</h2>
          <ul role="list" className="list-5 w-list-unstyled">
            <li className="list-item-3"><a href="/about" className="footer-link">О нас</a></li>
            <li className="list-item-3"><a href="https://www.linkedin.com/company/launch-turkey/jobs/" className="footer-link">Карьера</a></li>
          </ul>
        </div>
        <div className="div-block-369">
          <h2 className="footer-heading-2">Ресурсы</h2>
          <ul role="list" className="list-5 w-list-unstyled">
            <li className="list-item-3"><a href="/guide" className="footer-link">Блог</a></li>
            <li className="list-item-3"><a href="/guide" className="footer-link">Руководство основателя</a></li>
          </ul>
        </div>
        <div class="div-block-369"><h2 class="footer-heading-2">Поддерживать</h2><ul role="list" class="list-5 w-list-unstyled"><li class="list-item-3"><a href="/" target="_blank" class="footer-link">Центр помощи</a></li><li class="list-item-3"><a href="/" target="_blank" class="footer-link">Связаться с нами</a></li><li class="list-item-3 no"><a href="https://www.firstbase.io/search" class="footer-link">Поиск</a></li></ul></div></div></div><div class="div-block-367"></div>
        <div class="div-block-405"><div class="text-block-98">© 2023 Launch Turkey Inc. Все права защищены. &nbsp;· &nbsp;<a href="/terms" class="link-3">Условия эксплуатации</a> &nbsp;· &nbsp;<a href="/privacy-policy" class="link-3">политика конфиденциальности</a></div>
        <div class="text-block-98">&nbsp;<a href="https://www.linkedin.com/company/86823257" target="_blank" class="link-3">LinkedIn</a> </div></div></div></footer>

    <script src="https://res.cloudinary.com/avomeraydin/raw/upload/v1665402412/assets/libs/wow.min_fq5nxl.js"></script>
    <script src="https://res.cloudinary.com/avomeraydin/raw/upload/v1665402387/assets/libs/tobii.min_opmaxj.js"></script>
    <script src="https://res.cloudinary.com/avomeraydin/raw/upload/v1665402365/assets/libs/tiny-slider_m836x8.js"></script>
    <script src="https://res.cloudinary.com/avomeraydin/raw/upload/v1665402334/assets/libs/feather.min_mryhuk.js"></script>
    <script src="https://res.cloudinary.com/avomeraydin/raw/upload/v1665402298/assets/libs/plugins.init_qtdw96.js"></script>
    <script src="https://res.cloudinary.com/avomeraydin/raw/upload/v1665402298/assets/libs/app_g1gyad.js"></script>
    </>
  )
}
